<template>
  <main id="Dealer">
    <div class="map_toolbox">
      <div class="searchbox">
        <input
          placeholder="尋找 MJP經銷門市"
          @focus="show_seatch_list = true"
          v-model="search_keyword"
          type="text"
          class="sb__field"
        />
        <button
          @click="ClearKeyWord"
          class="clear_btn"
          :class="{ active: search_keyword.length != 0 }"
        ></button>

        <ul
          class="search_list"
          v-show="search_keyword.length != 0 && show_seatch_list"
        >
          <li
            v-for="item in shop_list.filter((shop) => {
              return (
                shop.address.indexOf(search_keyword) != -1 ||
                shop.name.indexOf(search_keyword) != -1
              );
            })"
            :key="item.shop_id"
            @click="ChooseShop(item.shop_id)"
          >
            {{ item.name }}
            <span>{{ item.address }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="shop_detailbox" v-show="search_box_show">
      <template v-if="search_shop_id != -1">
        <div class="title_box">
          <h3>
            {{
              shop_list.filter((shop) => {
                return shop.shop_id == search_shop_id;
              })[0].name
            }}
          </h3>

          <a
            target="_blank"
            :href="
              `https://www.google.com/maps/search/?api=1&amp;query=${
                shop_list.filter((shop) => {
                  return shop.shop_id == search_shop_id;
                })[0].address
              }`
            "
            class="more_btn link_mark"
          >
            <div><b>立即前往</b></div>
          </a>
        </div>
        <div class="address_box">
          <span class="material-icons-outlined"> location_on </span>
          <p>
            {{
              shop_list.filter((shop) => {
                return shop.shop_id == search_shop_id;
              })[0].address
            }}
          </p>
        </div>
        <div class="phone_box">
          <span class="material-icons-outlined"> call </span>
          <p>
            {{
              shop_list.filter((shop) => {
                return shop.shop_id == search_shop_id;
              })[0].phone
            }}
          </p>
        </div>
      </template>
    </div>
    <div id="map" @click="show_seatch_list = false"></div>
  </main>
</template>

<script>
import GSAP from "@/common/SmoothScrollResister.js";
import AxiosPlugin from "@/common/common_methods.js";
import { GetMetaData } from "@/common/meta";
import L from "leaflet";
let openStreetMap = {};
var myIcon = L.icon({
  iconUrl: "/img/map_icon.png",
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  popupAnchor: [0, -40],
});

export default {
  name: "Findus",
  mixins: [GSAP, AxiosPlugin],
  data() {
    return {
      shop_list: [],
      search_keyword: "",
      search_shop_id: -1,
      search_box_show: false,
      show_seatch_list: false,
      meta_data: null,
    };
  },
  components: {},
  watch: {
    search_keyword() {
      let choosen_shop = this.shop_list.filter((shop) => {
        return shop.shop_id == this.search_shop_id;
      })[0];
      if (this.search_box_show && this.search_keyword != choosen_shop.name) {
        this.search_box_show = false;
      }
    },
  },
  methods: {
    async GetShopList() {
      let result = await this.SendGetData(
        process.env.VUE_APP_BASE_API + "shop/get_shop_list.php"
      );
      if (result != "error") {
        this.shop_list = JSON.parse(result.data);
        this.UpdateMap();
        this.$nextTick(() => {
          window.prerenderReady = true;
        });
      }
    },
    UpdateMap() {
      let that = this;
      this.shop_list.forEach((shop) => {
        let marker = L.marker([shop.coordinatesX, shop.coordinatesY], {
          riseOnHover: true,
          icon: myIcon,
        }).addTo(openStreetMap);
        marker.on("click", function() {
          that.ChooseShop(shop.shop_id);
        });
      });
    },
    ClearKeyWord() {
      this.search_keyword = "";
      openStreetMap.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          openStreetMap.removeLayer(layer);
        }
      });
      openStreetMap.setView([23.983169, 120.532309], 8);
      this.search_shop_id = -1;
      this.UpdateMap();
    },
    ChooseShop(shop_index) {
      this.search_shop_id = shop_index;
      this.search_box_show = true;
      let choosen_shop = this.shop_list.filter((shop) => {
        return shop.shop_id == shop_index;
      })[0];
      this.show_seatch_list = false;
      this.search_keyword = choosen_shop.name;
      this.GA_ClickShop(choosen_shop.name);

      L.marker([choosen_shop.coordinatesX, choosen_shop.coordinatesY], {
        riseOnHover: true,
        icon: myIcon,
      }).addTo(openStreetMap);

      openStreetMap.setView(
        [choosen_shop.coordinatesX, choosen_shop.coordinatesY],
        18
      );
    },
    InitMap() {
      openStreetMap = L.map("map", {
        crs: L.CRS.EPSG3857,
        center: [23.983169, 120.532309],
        zoom: 8,
        zoomControl: false,
      });

      L.tileLayer(
        "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
        {
          maxZoom: 19,
          minZoom: 2,
          attribution: '© <a href="https://carto.com/attributions">CARTO</a>',
        }
      ).addTo(openStreetMap);
    },
    GetUserPosition(position) {
      openStreetMap.setView(
        [position.coords.latitude, position.coords.longitude],
        16
      );
    },
  },
  mounted() {
    this.RegisterGsap("Dealer");
    this.$emit("init-cursor");
    this.InitMap();
    this.GetShopList();
    let vm = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(vm.GetUserPosition);
    }
  },
  created() {
    this.meta_data = GetMetaData(
      "經銷據點",
      "茂建科技官方網站，您可以查詢距離最近的MJP特約經銷商以方便您前往購買與安裝。",
      ""
    );
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>

<style>
#Dealer {
  background-color: #000;
}
.leaflet-layer,
.leaflet-control-zoom-in,
.leaflet-control-zoom-out,
.leaflet-control-attribution {
  filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
}
</style>
